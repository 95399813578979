@import "../Kit/kit.css"; 
@import "containers.css"; 
@import "root.css";
@import "elements.css"; 
@import "animations.css"; 
@import "theme.css"; 
@import "other.css"; 

@font-face {font-family: "'BoutrosMBCDinkum Medium'";src: url('/public/fonts/light.ttf');}
@font-face {font-family: "'GE Dinkum Medium'";src: url("/public/fonts/bold.ttf");}
:root {
  /* transition: all 0.3s ease-in-out; */

  /* --shark: #8e9a9b;
  --goat: #b9cad4;
  --dolphin: #b1acac;
  --whale: #105588;
  --crow: #150d23; */
  
  --white:#ffffff;
  --black:#000000;
  --error:#f73131;
  --success:#61bb46;
  --warning:#f3d60b;

  --frog: #bada55;
  --penguin: #1fd1ec;

  --cyan:#63cfc9;
  --cyan-1:#84d7e2;
  --cyan-2:#a0e8cc;
  
  --blue:#40acdc;
  --green:#56e181;
  --green-1:#a5eb78;
  --orange :#f48114;
  --orange-1 :#ffa44e;
  --orange-2:#f9c089;
  --orange-3:#905929;

  --fox:#d69e58;
  --wolf: #b2a47b;
  --lion: #b59675;
  --tiger: #8d3c09;

  --dragon: #ec4245;
  --red:#dd3643;
  --red-1:#f29bb9;
  --fire: #e9b11f;

  --flamingo: #f29bb9;
  --purple: #a855f7;
  --jellyfish: #8a41e4;
  --lavender: #e4b1e4;

  --light-gray:  #9ca3af;
  --gray:  #4b5563;

  --main:#83d6e1;
  --main-2:#a3e4cb;
  background-color: var(--bg);
}

.light {  
    --prim:#FFFFFF;   --throne: #f6f6f6; --lord:    #FBFBFB;  --prince: #f6f6f6;  --king:#f6f6f6;
    --crow: #1f1d2b;  --shark:  #c4c4c7; --dolphin: #c3c3c6;  --goat:   #c4c4c7;  --whale: #bebec1;
    --bg:#fbfbfb;     --fill-logo:#2d303e; 
    --popup:#2c3e5022;
    --path:#c4c4c7;
    --border-1:#ecebe8;
    --not-found :#DCDCDD ; --bg-not-found:#f0f1f1; --text-not-found:#D9DDDD;
    --highlight:#FFFFFF22;
  }
  
  .dark {
    --prim: #2d303e;   --throne: #404453;   --lord:   #292a39;      --prince: #393c4a;     --king:  #3b3e4c;
    --crow: #ffffff;   --shark:  #c4c4c7;   --dolphin: #6c6e78;     --goat:   #9e9fa6;     --whale: #767982;
    --bg:#1f1d2b;      --fill-logo:#f6f6f6;
    --popup:#FBFBFB22;
    --path:#767982;
    --border-1:#494c5a;
    --not-found :#5e606d ; --bg-not-found:#494c5b; --text-not-found:#555869;
    --highlight:#2d303e22;
}

.bg-lin {
  background:linear-gradient(0deg, var(--prim) 0%, var(--prince) 100%);
}
.bg-lin-cyan {
  background:linear-gradient(0deg, var(--main) 0%, var(--main-2) 100%);
}

.item-card{
  position: relative;
  border-radius: var(--xl);
  box-shadow: var(--shadow-md);
  padding: var(--md);
  background-color: var(--prim);
  color: var(--crow); 
  /* animation: fade-in-list 0.3s ease-in-out; */
  background-color: var(--king);
  filter: blur(2px);
  opacity: 0;
  transition: all 300ms ease-out;
}

.intersecting{
  background-color: var(--prim);
  filter: blur(0px);
  opacity: 1;
}



@keyframes fade-in-list {
  0% {
    opacity: 0;
    background-color: var(--king);
  }
  100% {
    opacity: 1;
    background-color: var(--prim);
  }
}

.scroller-indecator{
  position: absolute;
  left: 10px;
  width: 10px;
  height: 100px;
  border-radius: 5px;
  background-color: var(--cyan);
  z-index: 10000;
  top: 0;
  /* transition: all 200ms linear; */
  /* margin-top: 20px; */
  /* top: 20px; */
  /* transform-origin: left;
  transition: transform 0.3s ease-in-out; */
}

.bg-white     {background-color: var(--white);}      .text-white     {color: var(--white);}      .fill-white     {fill: var(--white);}      
.bg-black     {background-color: var(--black);}      .text-black     {color: var(--black);}      .fill-black     {fill: var(--black);}      
.bg-frog      {background-color: var(--frog);}       .text-frog      {color: var(--frog);}       .fill-frog      {fill: var(--frog);}       
.bg-penguin   {background-color: var(--penguin);}    .text-penguin   {color: var(--penguin);}    .fill-penguin   {fill: var(--penguin);}    
.bg-crow      {background-color: var(--crow);}       .text-crow      {color: var(--crow);}       .fill-crow      {fill: var(--crow);stroke: var(--crow)}       
.bg-fox       {background-color: var(--fox);}        .text-fox       {color: var(--fox);}        .fill-fox       {fill: var(--fox);}        
.bg-wolf      {background-color: var(--wolf);}       .text-wolf      {color: var(--wolf);}       .fill-wolf      {fill: var(--wolf);}       
.bg-lion      {background-color: var(--lion);}       .text-lion      {color: var(--lion);}       .fill-lion      {fill: var(--lion);}       
.bg-tiger     {background-color: var(--tiger);}      .text-tiger     {color: var(--tiger);}      .fill-tiger     {fill: var(--tiger);}      
.bg-dragon    {background-color: var(--dragon);}     .text-dragon    {color: var(--dragon);}     .fill-dragon    {fill: var(--dragon);}     
.bg-fire      {background-color: var(--fire);}       .text-fire      {color: var(--fire);}       .fill-fire      {fill: var(--fire);}       
.bg-flamingo  {background-color: var(--flamingo);}   .text-flamingo  {color: var(--flamingo);}   .fill-flamingo  {fill: var(--flamingo);}   
.bg-jellyfish {background-color: var(--jellyfish);}  .text-jellyfish {color: var(--jellyfish);}  .fill-jellyfish {fill: var(--jellyfish);}  
.bg-lavender  {background-color: var(--lavender);}   .text-lavender  {color: var(--lavender);}   .fill-lavender  {fill: var(--lavender);} 
.bg-cyan      {background-color: var(--cyan);}       .text-cyan      {color: var(--cyan);}       .fill-cyan      {fill: var(--cyan);}
.bg-blue      {background-color: var(--blue);}       .text-blue      {color: var(--blue);}       .fill-blue      {fill: var(--blue);}
.bg-green     {background-color: var(--green);}      .text-green     {color: var(--green);}      .fill-green     {fill: var(--green);}
.bg-yellow    {background-color: var(--yellow);}     .text-yellow    {color: var(--yellow);}     .fill-yellow    {fill: var(--yellow);}
.bg-orange    {background-color: var(--orange);}     .text-orange    {color: var(--orange);}     .fill-orange    {fill: var(--orange);}
.bg-red       {background-color: var(--red);}        .text-red       {color: var(--red);}        .fill-red       {fill: var(--red);}
.bg-purple    {background-color: var(--purple);}     .text-purple    {color: var(--purple);}     .fill-purple    {fill: var(--purple);}
.bg-pink      {background-color: var(--pink);}       .text-pink      {color: var(--pink);}       .fill-pink      {fill: var(--pink);}
.bg-brown     {background-color: var(--brown);}      .text-brown     {color: var(--brown);}      .fill-brown     {fill: var(--brown);}
.bg-gray      {background-color: var(--gray);}       .text-gray      {color: var(--gray);}       .fill-gray      {fill: var(--gray);}
.bg-silver    {background-color: var(--silver);}     .text-silver    {color: var(--silver);}     .fill-silver    {fill: var(--silver);}
.bg-none      {background-color: unset!important;}    .text-none      {color: unset!important;}    .fill-none      {fill: unset!important;}

.bg-prim    {background-color: var(--prim);}     .text-prim    {color: var(--prim);}     .fill-prim    {fill: var(--prim);}     
.bg-throne  {background-color: var(--throne);}   .text-throne  {color: var(--throne);}   .fill-throne  {fill: var(--throne);}   
.bg-lord    {background-color: var(--lord);}     .text-lord    {color: var(--lord);}     .fill-lord    {fill: var(--lord);}     
.bg-prince  {background-color: var(--prince);}   .text-prince  {color: var(--prince);}   .fill-prince  {fill: var(--prince);}   
.bg-king    {background-color: var(--king);}     .text-king    {color: var(--king);}     .fill-king    {fill: var(--king);}     
.bg-crow    {background-color: var(--crow);}     .text-crow    {color: var(--crow);}     .fill-crow    {fill: var(--crow);}     
.bg-shark   {background-color: var(--shark);}    .text-shark   {color: var(--shark);}    .fill-shark   {fill: var(--shark);}    
.bg-dolphin {background-color: var(--dolphin);}  .text-dolphin {color: var(--dolphin);}  .fill-dolphin {fill: var(--dolphin);}  
.bg-goat    {background-color: var(--goat);}     .text-goat    {color: var(--goat);}     .fill-goat    {fill: var(--goat);}     
.bg-whale   {background-color: var(--whale);}    .text-whale   {color: var(--whale);}    .fill-whale   {fill: var(--whale);}    
.bg-popup   {background-color: var(--popup);}    .text-popup   {color: var(--popup);}    .fill-popup   {fill: var(--popup);}
.fill-logo{fill: var(--fill-logo);}


.svg-icon {fill:var(--path)}
.svg-icon .border{fill:var(--border-1)}

.stroke-drop-shdow{
  filter: drop-shadow(0px -6px 1px #d9dddd);
  stroke:#eff3f3 ;
  fill: #eff3f3;
}
.dark .stroke-drop-shdow{
  filter: none;
  stroke:#474B60 ;
  fill: #474B60;
}
