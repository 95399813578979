.toast-holder{
    position: fixed;
    right: 0;
    top: 0;
    transition: all 450ms linear;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    z-index: 400;
}

.toast-container{
    position: relative;
    transition: all 450ms linear;
    right: 10px;
    top: 30px;
    gap: var(--md);
    z-index: 3333;
    display: flex;
    flex-direction: column;
    border-radius: var(--lg);
    box-shadow: var(--shadow-lg);
    padding: var(--md) var(--lg);
    font-family: var(--font-bold);
    min-width: 200px;
    max-width: 400px;
    margin-bottom: 10px;
}

.toast-container .x{
    font-size:14px;
    color: #fff;
    opacity: 0.5;
    border-radius: 5px;
    padding-inline: 5px;
    margin-left: var(--sm);
    cursor: pointer;
    font-weight: bold;
    margin-right: -4px;
    margin-top: -4px;
}
.toast-container .x::after{content: "x";}
.toast-container .toast-content {font-weight: 500;padding-inline: var(--md);}
.info-fade-in{
    animation: fade-in 400ms ease-out !important;
}

.info-fade-out{
    overflow: hidden;
    opacity: 0;
    scale: 0;
    transition: all 400ms ease-out;
    animation: fade-out 500ms ease-out !important;
}

@keyframes fade-in {
    0% {scale: 0;opacity: 0;right: -400px;padding: 0;}
    70% {scale: 1.1;right: 50px;padding: 10px 40px;}
    100% {scale: 1;opacity: 1;right: 10px;}
}

@keyframes fade-out { 0% {opacity: 1;scale: 1; } 70% {opacity: 0.1;scale: 0;} 100% {opacity: 0;right: -400px;scale: 0;} }