/* T O G G L E */
.kit-toggled-container{
    display: inline-flex;
    align-items: center;
}
.kit-toggle-button{
    cursor: pointer;
    padding: 2px;
    margin: 0 4px;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    background-color: var(--crow);
    box-shadow: var(--shadow-md);
    padding-left: 22px;
    display: inline-flex;
    padding-right: 2px;
}
.kit-toggle-button::after{
    content: "";
    padding: 9px;
    border-radius: 10px;
    background-color: var(--prim);
    box-shadow: var(--shadow-lg);
}
.kit-toggle-active {
    padding-right: 22px;
    padding-left: 2px;
    background-color:var(--frog);
}
/*TOGGLE*/

/* P O P U P S E L E C T O R  */
.popup-selector-button-svg{
    fill: var(--crow) !important;
}
/* .popup-selector-button{
    display: flex;
    flex-direction: row;
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--crow);
    padding: var(--sm) var(--md) ;
    cursor: pointer;
    box-shadow: var(--shadow-md);
    background-color: var(--prim);
    border-radius: var(--md);
    align-items: center;
    gap: var(--md);
    font-size: var(--font-sm);
} */
.popup-selector-container{
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(2px);
    transition: all 0.2s ease-in-out;
    animation:  bluer 0.2s;
    z-index: 999;
    justify-content: center;
    padding: var(--md);
    background-color:var(--popup);
}
.popup-selector-child{
    display: flex;
    flex-direction: column;
    margin: auto;
    color: var(--crow);
    box-shadow: var(--shadow-md);
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
    border-radius:var(--md);
    gap: var(--md);
    padding: var(--md);
    width: calc(100% - 2 * var(--md)); 
    animation: popup-fade 0.2s;
    max-width: 400px;
    background-color: var(--prim) ;
    padding: var(--lg);
}
.popup-selector-option{
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--crow);
    padding: 10px 20px;
    border-radius:var(--md);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    box-shadow: var(--shadow-md);
    background-color: var(--prince);
}
/* POPUP SELECTOR */

/* S E L E C T O R  */
.selector-button-svg{
    fill: var(--crow) !important;
    transition: all 0.3s;
}
.selector-button{
    display: flex;
    flex-direction: row;
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--crow);
    padding: var(--sm) var(--md) ;
    cursor: pointer;
    box-shadow: var(--shadow-md);
    background-color: var(--cyan);
    border-radius: var(--md);
    align-items: center;
    gap: var(--md);
    font-size: var(--font-sm);
    position: relative;
    overflow: visible;
}
.selector-child{
    transition: all 0.2s ease-in-out;
    z-index: 999;
    top: 30px;
    right: 0px;
    display: flex;
    position: absolute;
    flex-direction: column;
    color: var(--crow);
    box-shadow: var(--shadow-md);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    border-radius:var(--md);
    gap: var(--md);
    padding: var(--lg);
    background-color: var(--prim) ;
    animation:  fade-in-options 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    max-height: 30vh;
    width: calc(50vw - 2 * var(--md)); 
    max-width: 300px; 
}
.selector-option{
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--crow);
    padding: 10px 20px;
    border-radius:var(--md);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    box-shadow: var(--shadow-md);
    background-color: var(--prince);
}



.selector-option:hover{
    opacity: 1;
    box-shadow: inset 0 -2px 8px #ccc5;
}
.selected-option{
    opacity: 0.6;
    background-color: var(--shark) ;
    color: var(--prim);
}

@keyframes fade-in-options {
    0%{
        opacity: 0;
        max-height: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        max-height: 30vh;
    }
}
/* SELECTOR */

/* S E A R C H  */

/*SEARCH*/

/* O P T I O N S */
.toggle-options-container {
    background-color: var(--bg);
    gap: var(--md);
    padding: var(--xs);
    border-radius:var(--md);
    box-shadow: var(--shadow-md);
    overflow-x: auto;
    transition: all 200ms ease-out;
    display:grid;
    overflow-y: hidden;
    grid-template-rows: repeat(auto-fill,minmax( auto, 0.35fr)); 
    grid-auto-flow: column;
}

.toggle-options-container::-webkit-scrollbar { height: 4px;width: 4px;}
.toggle-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: var(--md);}
.toggle-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;}
.toggle-options-container::-webkit-scrollbar-thumb {  border-radius: 6px;}
.toggle-options-container:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d55;}

.toggle-options-title {
    font-family: var(--font-bold);
    font-weight: bold;
}

.toggle-option{
    border-radius:var(--md);
    padding:var(--xs) var(--lg);
    font-size: var(--font-sm);
    border: none;
    cursor: pointer;
    font-weight: bold;
    box-shadow: var(--shadow-md);
    font-family: var(--font-bold);
    font-weight: bold;
    min-width: max-content;
    background-color: var(--lord);
    color: var(--crow);
}
.toggle-option:hover{
    transform: translateY(1px);
    opacity: 1;
}
.toggled-option{
    background-color: var(--shark) !important;
    color: var(--prim);
    opacity: 0.7;
    position: sticky;
    right: 0;
}
/* OPTIONS */
.cut-words{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}