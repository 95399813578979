.form-container{
    --active:#1fd1ec;
    --light:#9ca3af;

    --rainbo: #f87171;
    --grass:#bada55;

    --shadow-md: 0px 0px 10px rgb(45, 48, 62,0.15);
    --shadow-xl:0px 0px 6px rgba(95, 20, 20, 0.16);

    --shadow-focus: 0px 0px 6px rgba(32, 211, 238,0.5);
    --shadow-active:0px 0px 5px rgba(32, 211, 238,0.5);
}

/* Cleaner */
.form-cleaner{
    flex-wrap:  wrap;
    display:  flex;
    gap: var(--md);
    box-shadow: inset 0px 0px 8px var(--dragon);
    padding: var(--md);
    border-radius:var(--md);
    animation: fade-in 400ms ease-in;
    justify-self: baseline;
    max-width: max-content;
}

/* Container */
.form-container {
    padding: var(--sm);
    gap: var(--md);
    margin-block:5px;
    background-color: var(--background);
    max-width: 100%;
    /* border-radius:var(--md); */
    /* box-shadow:var(--shadow-md); */
    /* border-bottom: var(--crow-2) solid 1px; */
}

/* Section */
.form-section {
    gap: var(--lg);    
    border-radius:var(--md);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: auto;
    align-items: center;
    padding: var(--xs);
}

.more-options-section {
    display: flex;
    flex-direction: column;
    gap: var(--md);    
    border-radius:var(--md);
    padding: var(--xs);
}

.clear-icon{
    color: rgb(252, 165, 165);
    font-size: 24px;
    /* background-color: var(--lord); */
    font-family: var(--font-bold);
    font-weight: 300;
    left: var(--md);
    padding-right: 3px;
    padding-top: 2px;
    z-index: 10;
    position: absolute;
    cursor: pointer;
}
.clear-icon.hide{
    display: none;
}


/* Selector */


/* Date */
.owl-label-container{
    display:  flex ;
    flex-direction: row;
    align-items: center;
    gap: var(--xs);
    padding:var(--md);
    border-radius:var(--md);
    background-color: var(--lord);
    box-shadow: var(--shadow-md);
    gap: var(--md);
    max-height: var(--md);
}

.owl-label{
    color: var(--shark);
    font-size:var(--font-sm);
    font-weight: 300;
    font-family: var(--font-bold);
}

.owl-date{
    outline: none;
    border: none;
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--shark);
    background-color: var(--lord);
    max-width: 95px;
}

/* Button */
.owl-button-conatiner{
    position: relative;
}

.owl-button{
    font-family: var(--font-bold);
    background-color: var(--lord);
    color: var(--rainbo);
    border-radius:var(--md);
    padding: 4px 16px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-md);
}

.owl-button-conatiner{
    position: relative;
}
.selected{
    /* box-shadow: var(--shadow-active) !important; */
    background-color:var(--active) ;
    color: var(--white) !important;
}
.cannot-select svg{
    opacity: 0.2 !important;
}

.owl-popup-container{
    position: absolute;
    inset: 0;
    background-color:#2c3e5022;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(1px);
    /* transition: all 0.1s ease-in-out; */
    animation:  bluer 0.1s;
}
@keyframes bluer {
    0% {
        background-color:#ccc0;
        backdrop-filter: blur(0px);
    }
    100% {
        background-color:var(--popup);
        backdrop-filter: blur(2px);
    }
}


.popup-builder{
    position: fixed;
    inset: 0;
    background-color:var(--prim);
    backdrop-filter: blur(1px);
    transition: all 0.1s ease-in-out;
    animation:  bluer 0.1s;
    z-index: 300;
    padding: 100px var(--md);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.popup-child{
    margin: auto;
    gap: var(--md);
    animation: popup-fade 0.2s;
    max-width: 500px;
    width: 100%;
}
@keyframes popup-fade {
    0% {
        opacity: 0;
        transform:scale(1,0.5) translateY(700px) 
    }
    70% {
        transform: translateY(-20px) skewY(-5deg);
        
    }
    100% {
        opacity: 1;
        transform:scale(1) translateY(0) skewY(0);
    }
}
.scanner{
    background-color: transparent !important;
}