@import "./Lib/css/index.css";
.undefined::after {
    content: "Undefined className value";
    color: var(--white);
    font-weight: bold;
    font-size: var(--font-sm);
    font-family: var(--font-bold);
    padding: var(--md);
    border-radius: var(--md);
    background-color: var(--red);
    box-shadow: var(--shadow-md);
    margin-left: var(--md);
}
